$sizes: 0, 5, 10, 15, 20, 30;

@each $size in $sizes {
  .m-#{$size} {
    margin: #{$size}px;
  }
  .mt-#{$size} {
    margin-top: #{$size}px;
  }
  .mb-#{$size} {
    margin-bottom: #{$size}px;
  }
  .ml-#{$size} {
    margin-left: #{$size}px;
  }
  .mr-#{$size} {
    margin-right: #{$size}px;
  }
  .my-#{$size} {
    margin-top: #{$size}px;
    margin-bottom: #{$size}px;
  }
  .mx-#{$size} {
    margin-right: #{$size}px;
    margin-left: #{$size}px;
  }
  .p-#{$size} {
    padding: #{$size}px;
  }
  .pt-#{$size} {
    padding-top: #{$size}px;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}px;
  }
  .pl-#{$size} {
    padding-left: #{$size}px;
  }
  .pr-#{$size} {
    padding-right: #{$size}px;
  }
  .py-#{$size} {
    padding-top: #{$size}px;
    padding-bottom: #{$size}px;
  }
  .px-#{$size} {
    padding-right: #{$size}px;
    padding-left: #{$size}px;
  }
}

@for $i from 0 through 3 {
  .order-#{$i} {
    order: $i;
  }
}

.rounded-0 {
  border-radius: 0;
}

.mt-auto {
  margin-top: auto;
}

.w-100 {
  width: 100%;
}

.bg-gray {
  background: #f7f7f7;
}

.text-semi-bold {
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 600;
}

.text-white {
  color: #ffffff;
}

.text-gray {
  color: #9fa3a8;
}

.text-dark-gray {
  color: #767b82;
}

.text-green {
  color: #41d6c3;
}

.text-red {
  color: var(--color-error);
}

.text-blue {
  color: var(--color-primary);
}

.text-black {
  color: #404852;
}

.text-dark-blue {
  color: #195178;
}

.text-yellow {
  color: #ffcd00;
}

.list-clean {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img-white {
  filter: brightness(0) invert(1);
}

.img-gray {
  filter: grayscale(1);
}

.img-flip-y {
  transform: rotateY(180deg);
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-grow-1 {
  flex-grow: 1;
}

.img-responsive {
  max-width: 100%;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--square {
    border-radius: 4px;
  }

  &.thumbnail--md {
    width: 60px;
    height: 60px;
  }
}

.align-items-center {
  align-items: center;
}

.link-blue {
  cursor: pointer;
  color: #42a1ec;

  &:active,
  &:focus,
  &:hover {
    color: #42a1ec;
    text-decoration: underline;
  }
}

.card {
  background: #ffffff;
  border-radius: 4px;

  &-body {
    padding: 15px;
  }
}

.pill {
  &-checkbox {
    margin: 0;
    cursor: pointer;

    &__label {
      padding: 8px;
      background: rgba(236, 237, 238, 0.5);
      border-radius: 4px;
      transition: 0.3s all ease;

      &:hover {
        background: #ecedee;
      }

      &.active {
        background: rgba(204, 228, 245, 0.5);
        font-weight: 600;
      }
    }
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;

  [class^='col'] {
    padding-left: 15px;
    padding-right: 15px;
  }

  &--around {
    &-xs {
      margin: -5px;

      [class^='col'] {
        padding: 5px;
      }
    }

    &-sm {
      margin: -7.5px;
      margin: -7.5px;

      > [class^='col'] {
        padding: 7.5px;
        padding: 7.5px;
      }
    }
  }

  .col {
    flex: 1 0 0%;
  }

  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-12 {
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
    }
  }
}

.m {
  &l {
    &-1 {
      margin-left: 5px;
    }
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block;
  }

  .d-md-none {
    display: none;
  }

  .d-md-flex {
    display: flex;
  }
}

.doc-system-box {
  & > svg {
    padding: 0 14px 0px 16px;
  }

  @media (max-width: 767px) {
    width: 90%;
  }

  @media (min-width: 768px) {
    min-width: 20%;
  }
}
