#hidden-video {
  height: 0;
}
.video-layout {
  display: block;
  height: 100%;
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #293548;
}
.video-wrapper {
  overflow: hidden;
  display: flex;
  flex: 1;
  height: auto;
}
.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #000000;
  border-radius: 10px;
  height: calc(100vh - 60px);

  &.video-mobile-resolution {
    video {
      object-fit: contain;
    }
  }

  .player-wrapper {
    height: 100%;
  }

  video {
    object-fit: cover;
  }

  .main-video-cam {
    margin: auto;
    height: 100% !important;
  }
  &-small {
    position: absolute;
    max-width: 145px;
    align-items: flex-start;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px 5px calc(env(safe-area-inset-bottom, 0) + 10px);
    top: 0;
    transition: height 0.3s ease-in;
    right: 0;
    z-index: 251;
    background-color: transparent;

    .video-container-small-wrapper {
      background-color: #000000;
      height: 120px !important;
      min-height: 120px;
      min-width: 120px;
      width: 120px;
      border-radius: 4px;
    }

    video {
      display: inline-block;
      position: relative;
      background-size: contain;
      margin: 0;
      background-color: #000000;
      height: 120px !important;
      min-height: 120px;
      min-width: 120px;
      width: 120px;
      border-radius: 4px;
    }
  }
}

.vertical-view {
  .video-wrapper {
  }
}
.tile-view {
  display: flex;
  flex-direction: column-reverse;
  .video-wrapper {
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .video-container {
    height: 100%;
    width: 100%;
    @media (min-width: 768px) {
      max-width: 556px;
      max-height: 90%;
    }
  }
  &.single {
    .video-container {
      max-height: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }
}

.stream-component {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &__video {
    width: 100%;
    height: 100%;
    float: left;
    cursor: pointer;
    object-fit: contain;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h4 {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .thumbnail {
      width: 80px;
      height: 80px;
    }
  }
}

.premeeting-screen {
  background: #fff;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-direction: column;
  font-size: 1.3em;
  z-index: 251;
  padding-top: 50px;
  @media (max-width: 414px) {
    padding-top: 30px;
  }
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 24px;
    z-index: 252;
    @media (max-width: 320px) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
    .title {
      color: var(--color-darkGrey);
      font-size: 24px;
      font-weight: 800;
      width: 55%;
      text-align: center;
      line-height: 32px;
      margin-bottom: 18px;
      @media (max-width: 320px) {
        width: 90%;
        font-size: 20px;
        line-height: 28px;
      }
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
      @media (min-width: 1200px) {
        width: 20%;
      }
    }
  }
  #preview {
    position: relative;
    margin: 25px auto;
    overflow: hidden;
    z-index: 252;
    width: 40%;
    display: flex;
    justify-content: center;
    padding: 0;
    @media (max-width: 320px) {
      min-width: auto;
      width: 80% !important;
    }
    @media (max-width: 767px) {
      width: 70%;
    }
    @media (min-width: 768px) {
      width: 36%;
      margin-top: 0;
    }
    @media (min-width: 1441px) {
      margin-top: 25px;
    }
  }
  .preview-caption-text {
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    padding: 10px;
    background: linear-gradient(to top, #000000b8, transparent);
  }
  .prejoin-chat-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 42px;
    @media (max-width: 414px) {
      margin-top: 32px;
    }
    &__text {
      color: #767b82;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    &__action {
      color: #007acc;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 10px;
      img {
        width: 15px;
        max-width: 100%;
        margin: 0 5px;
      }
    }
  }
  .prejoin-button-container {
    @media (max-width: 320px) {
      width: 100%;
    }
    .action-btn {
      box-sizing: border-box;
      color: #fff;
      background: #31a598;
      border: 1px solid #31a598;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      padding: 7px 16px;
      position: relative;
      text-align: center;
      width: 320px;
      @media (max-width: 320px) {
        width: 100%;
      }
      .icon-button {
        width: 18px;
        height: 17px;
        margin-right: 6px;
        position: relative;
        top: 3px;
      }
    }
  }
}
.network-indicator {
  height: auto !important;
  width: auto;
  position: absolute;
  padding: 4px 8px;
  left: 18px;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear 0s;
  z-index: 99;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.9);
  }
  &-table {
    z-index: 9;
    top: 40px;
    left: 20px;
    position: absolute;
    background-color: #333244;
    color: #fff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
}
